import PortfolioDetail from "../Component/Portfolio/PortfolioDetail";

const PortfolioDetailPage = () => {
    return (
        <div style={{ display: "flex" }}>
            <PortfolioDetail />
        </div>
    );
};

export default PortfolioDetailPage;
