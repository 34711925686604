import GameMain from "../Game/Main/gameStart";

const SkrrrGame = () => {
  return (
    <>
      <GameMain />
    </>
  );
};

export default SkrrrGame;
