import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0; 
    font-family: "SCDream5", "Noto Sans";
    box-sizing: border-box;
}


body{
}
`;
