import PfCreate from "../Component/Portfolio/PfCreate";

const PortfolioNo = () => {
  return (
    <div className="PortfolioNo">
      <PfCreate />
    </div>
  );
};

export default PortfolioNo;
