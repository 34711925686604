import styled from "styled-components";

export const ChartStyle = styled.div`
  width: 95%;
  margin-bottom: 30px;
`;

export const ChartDate = styled.p`
  font-family: "SCDream9";
`;
